<div class="test-page">
  <div class="test-page__images">
    <img class="test-page__images__one test-page__images__left" src="/assets/test-property-one/test-image-1.jpg">
    <img class="test-page__images__one test-page__images__right" src="/assets/test-property-one/test-image-2.jpg">
  </div>

  <div class="test-page__description">
    <div class="test-page__description__address">
      <h2 class="heading-secondary">123 Peachtree St. Atlanta, GA 30303</h2>
      <h2 class="heading-secondary">$1,200,000</h2>
    </div>
    <hr class="test-page__line">
    <div class="test-page__description__body">
      <div class="test-page__description__body__text">
        <p class="paragraph-one">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
          cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
          non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          Purus gravida quis blandit turpis cursus in. Cursus turpis massa tincidunt
          dui. Eu augue ut lectus arcu bibendum at varius vel pharetra. Sit amet tellus
          cras adipiscing enim eu turpis egestas pretium. Cursus sit amet dictum sit
          amet justo. Viverra mauris in aliquam sem fringilla ut morbi tincidunt augue.
          Pretium vulputate sapien nec sagittis aliquam malesuada bibendum arcu vitae.
          Facilisis leo vel fringilla est. Ornare suspendisse sed nisi lacus sed viverra
          tellus in hac. Aliquam ultrices sagittis orci a scelerisque purus semper. Eu
          nisl nunc mi ipsum faucibus vitae aliquet nec ullamcorper.
        </p>
      </div>

      <div class="test-page__description__body__facts">
        <div class="test-page__description__body__facts__wrapper">
          <div class="test-page__description__body__facts__row">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M80-200v-240q0-27 11-49t29-39v-112q0-50 35-85t85-35h160q23 0 43 8.5t37 23.5q17-15 37-23.5t43-8.5h160q50 0 85 35t35 85v112q18 17 29 39t11 49v240h-80v-80H160v80H80Zm440-360h240v-80q0-17-11.5-28.5T720-680H560q-17 0-28.5 11.5T520-640v80Zm-320 0h240v-80q0-17-11.5-28.5T400-680H240q-17 0-28.5 11.5T200-640v80Zm-40 200h640v-80q0-17-11.5-28.5T760-480H200q-17 0-28.5 11.5T160-440v80Zm640 0H160h640Z"/></svg>
            <p class="paragraph-one">3 beds</p>
          </div>
          <div class="test-page__description__body__facts__row">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M320-240q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm160 0q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm160 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240ZM200-400v-80q0-106 68-184t172-92v-84h80v84q104 14 172 92t68 184v80H200Zm80-80h400q0-83-58.5-141.5T480-680q-83 0-141.5 58.5T280-480Zm40 360q-17 0-28.5-11.5T280-160q0-17 11.5-28.5T320-200q17 0 28.5 11.5T360-160q0 17-11.5 28.5T320-120Zm160 0q-17 0-28.5-11.5T440-160q0-17 11.5-28.5T480-200q17 0 28.5 11.5T520-160q0 17-11.5 28.5T480-120Zm160 0q-17 0-28.5-11.5T600-160q0-17 11.5-28.5T640-200q17 0 28.5 11.5T680-160q0 17-11.5 28.5T640-120ZM480-480Z"/></svg>
            <p class="paragraph-one">2 baths</p>
          </div>
          <div class="test-page__description__body__facts__row">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 125" enable-background="new 0 0 100 100" xml:space="preserve"><g><g><path class="measure-icon" d="M94.197,91.381L8.438,5.613C7.863,5.042,6.996,4.873,6.251,5.179C5.502,5.498,5.014,6.225,5.014,7.035l0.009,85.933    c0,1.106,0.884,2.004,1.995,2.004h85.956c1.111,0,2.013-0.897,2.013-2.004C94.986,92.32,94.673,91.746,94.197,91.381z     M9.025,90.962V11.875l9.675,9.675l-4.809,4.81c-0.78,0.782-0.78,2.058,0,2.838c0.388,0.388,0.906,0.588,1.42,0.588    c0.509,0,1.023-0.2,1.411-0.588l4.816-4.812l8.577,8.578l-4.807,4.811c-0.78,0.787-0.78,2.052,0,2.833    c0.388,0.393,0.902,0.588,1.42,0.588c0.514,0,1.028-0.196,1.416-0.588l4.81-4.804l8.516,8.514l-4.81,4.818    c-0.779,0.778-0.779,2.05,0,2.834c0.396,0.392,0.911,0.588,1.42,0.588c0.514,0,1.028-0.196,1.42-0.588l4.81-4.812l8.52,8.521    l-4.813,4.812c-0.771,0.78-0.771,2.052,0,2.836c0.396,0.392,0.911,0.588,1.429,0.588c0.514,0,1.015-0.196,1.416-0.593l4.805-4.809    l8.525,8.524l-4.809,4.81c-0.785,0.78-0.785,2.052,0,2.832c0.388,0.397,0.901,0.594,1.419,0.594c0.515,0,1.023-0.196,1.411-0.594    l4.813-4.807l8.525,8.524l-4.812,4.807c-0.785,0.775-0.785,2.048,0,2.843c0.388,0.388,0.905,0.579,1.419,0.579    c0.51,0,1.021-0.191,1.412-0.579l4.815-4.813l9.729,9.729H9.025z"/><path d="M22.634,50.734c-0.571-0.582-1.433-0.75-2.182-0.438c-0.745,0.307-1.237,1.036-1.237,1.852v26.616    c0,1.11,0.897,2.008,2.008,2.008h26.643c1.111,0,2.013-0.897,2.013-2.008c0-0.646-0.313-1.218-0.788-1.584L22.634,50.734z     M23.231,76.763l-0.009-19.771l19.781,19.774L23.231,76.763z"/></g></g></svg>
            <p class="paragraph-one">5000 sqft</p>
          </div>
          <div class="test-page__description__body__facts__row">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path class="build-icon" d="M756-120 537-339l84-84 219 219-84 84Zm-552 0-84-84 276-276-68-68-28 28-51-51v82l-28 28-121-121 28-28h82l-50-50 142-142q20-20 43-29t47-9q24 0 47 9t43 29l-92 92 50 50-28 28 68 68 90-90q-4-11-6.5-23t-2.5-24q0-59 40.5-99.5T701-841q15 0 28.5 3t27.5 9l-99 99 72 72 99-99q7 14 9.5 27.5T841-701q0 59-40.5 99.5T701-561q-12 0-24-2t-23-7L204-120Z"/></svg>
            <p class="paragraph-one">Built in 2008</p>
          </div>
        </div>
      </div>
    </div>

    <div class="test-page__features">
      <!-- <h2 class="heading-secondary">Features & Amenities</h2> -->
      <div class="test-page__features__body">
        <div class="test-page__features__section test-page__features__interior">
          <h2 class="heading-secondary test-page__features__section__header">Interior</h2>
          <div class="test-page__features__fact">
            <p class="paragraph-one test-page__features__fact__header">Total Bedrooms</p>
            <p class="paragraph-one test-page__features__fact__body">3</p>
          </div>
          <div class="test-page__features__fact">
            <p class="paragraph-one test-page__features__fact__header">Total Bathrooms</p>
            <p class="paragraph-one test-page__features__fact__body">2</p>
          </div>
          <div class="test-page__features__fact">
            <p class="paragraph-one test-page__features__fact__header">Laundry Room</p>
            <p class="paragraph-one test-page__features__fact__body">Main Level</p>
          </div>
          <div class="test-page__features__fact">
            <p class="paragraph-one test-page__features__fact__header">Flooring</p>
            <p class="paragraph-one test-page__features__fact__body">Hardwood</p>
          </div>
          <div class="test-page__features__fact">
            <p class="paragraph-one test-page__features__fact__header">Appliances</p>
            <p class="paragraph-one test-page__features__fact__body">Dishwasher, Disposal, Dryer, Gas Range, Gas Water Heater, Microwave, Range Hood, Refrigerator, Washer</p>
          </div>
        </div>

        <div class="test-page__features__section test-page__features__exterior">
          <h2 class="heading-secondary test-page__features__section__header">Exterior</h2>
          <div class="test-page__features__fact">
            <p class="paragraph-one test-page__features__fact__header">Stories</p>
            <p class="paragraph-one test-page__features__fact__body">2</p>
          </div>
          <div class="test-page__features__fact">
            <p class="paragraph-one test-page__features__fact__header">Garage Space</p>
            <p class="paragraph-one test-page__features__fact__body">2</p>
          </div>
          <div class="test-page__features__fact">
            <p class="paragraph-one test-page__features__fact__header">Water Source</p>
            <p class="paragraph-one test-page__features__fact__body">Public</p>
          </div>
          <div class="test-page__features__fact">
            <p class="paragraph-one test-page__features__fact__header">Utilities</p>
            <p class="paragraph-one test-page__features__fact__body">Cable Available, Electricity Available, Natural Gas Available, Phone Available, Sewer Available</p>
          </div>
          <div class="test-page__features__fact">
            <p class="paragraph-one test-page__features__fact__header">Heat Type</p>
            <p class="paragraph-one test-page__features__fact__body">Forced Air, Natural Gas</p>
          </div>
          <div class="test-page__features__fact">
            <p class="paragraph-one test-page__features__fact__header">Security Features</p>
            <p class="paragraph-one test-page__features__fact__body">Closed Circuit Camera(s), Secured Garage/Parking, Security Gate, Security System Owned, Smoke Detector(s)</p>
          </div>
        </div>
        <div class="test-page__features__section">
          <h2 class="heading-secondary test-page__features__section__header">Area & Lot</h2>
          <div class="test-page__features__fact">
            <p class="paragraph-one test-page__features__fact__header">Status</p>
            <p class="paragraph-one test-page__features__fact__body">For sale</p>
          </div>
          <div class="test-page__features__fact">
            <p class="paragraph-one test-page__features__fact__header">Lot Area</p>
            <p class="paragraph-one test-page__features__fact__body">2.4 acres</p>
          </div>
          <div class="test-page__features__fact">
            <p class="paragraph-one test-page__features__fact__header">Type</p>
            <p class="paragraph-one test-page__features__fact__body">Residential</p>
          </div>
          <div class="test-page__features__fact">
            <p class="paragraph-one test-page__features__fact__header">Year Built</p>
            <p class="paragraph-one test-page__features__fact__body">1930</p>
          </div>
          <div class="test-page__features__fact">
            <p class="paragraph-one test-page__features__fact__header">Neighborhood</p>
            <p class="paragraph-one test-page__features__fact__body">Buckhead</p>
          </div>
          <div class="test-page__features__fact">
            <p class="paragraph-one test-page__features__fact__header">Elementary School</p>
            <p class="paragraph-one test-page__features__fact__body">Jackson - Atlanta</p>
          </div>
          <div class="test-page__features__fact">
            <p class="paragraph-one test-page__features__fact__header">Middle School</p>
            <p class="paragraph-one test-page__features__fact__body">Willis A. Sutton</p>
          </div>
          <div class="test-page__features__fact">
            <p class="paragraph-one test-page__features__fact__header">High School</p>
            <p class="paragraph-one test-page__features__fact__body">North Atlanta</p>
          </div>
        </div>
        <div class="test-page__features__section">
          <h2 class="heading-secondary test-page__features__section__header">Financial</h2>
          <div class="test-page__features__fact">
            <p class="paragraph-one test-page__features__fact__header">Sales Price</p>
            <p class="paragraph-one test-page__features__fact__body">$1,200,000</p>
          </div>
          <div class="test-page__features__fact">
            <p class="paragraph-one test-page__features__fact__header">Real Estate Tax</p>
            <p class="paragraph-one test-page__features__fact__body">$99,999</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>