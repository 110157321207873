import { TestPropertyPageComponent } from './pages/test-property-page/test-property-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { AboutMePageComponent } from './pages/about-me-page/about-me-page.component';
import { ServicesOfferedPageComponent } from './pages/services-offered-page/services-offered-page.component';

const routes: Routes = [{
  path: "",
  component: HomePageComponent
}, {
  path: "test-property",
  component: TestPropertyPageComponent
}, {
  path: "about-me",
  component: AboutMePageComponent
}, {
  path: "services-offered",
  component: ServicesOfferedPageComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
