import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UtilModule } from './modules/util/util.module';
import { HttpClientModule } from '@angular/common/http';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { TestPropertyPageComponent } from './pages/test-property-page/test-property-page.component';
import { NavComponent } from './components/nav/nav.component';
import { CalendlyWidgetComponent } from './components/nav/calendlywidget/calendlywidget.component';
import { AboutMePageComponent } from './pages/about-me-page/about-me-page.component';
import { ServicesOfferedPageComponent } from './pages/services-offered-page/services-offered-page.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    TestPropertyPageComponent,
    NavComponent,
    CalendlyWidgetComponent,
    AboutMePageComponent,
    ServicesOfferedPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    UtilModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
