import { 
  Component, 
  NgModule, 
  ElementRef,
  ViewChild
} from '@angular/core';

declare global {
  interface Window {
     Calendly: any;
  }
}

@Component({
  selector: 'app-calendly-widget',
  templateUrl: './calendlywidget.component.html',
  styleUrls: ['./calendlywidget.component.scss']
})
export class CalendlyWidgetComponent {
  @ViewChild('container') container: ElementRef;
  
  ngAfterViewInit() {
    window.Calendly.initInlineWidget({
      url: 'https://calendly.com/andreapaniagua-atlantafinehomes/30min?month=2024-06',
      parentElement: this.container.nativeElement
    });
  }
}