<div class="hp">
  <div class="hp__about">
    <div class="hp__about__img">
      <div class="hp__about__img-wrapper"></div>
    </div>
    <div class="hp__about__description">
      <h1 class="heading-primary hp__about__title">Meet Andrea</h1>
      <p class="paragraph-one hp__about__body">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
        sed do eiusmod tempor incididunt ut labore et dolore magna
        aliqua. Nec ultrices dui sapien eget mi proin. Hac
        habitasse platea dictumst vestibulum rhoncus est
        pellentesque elit ullamcorper. Eu consequat ac felis
        donec.
      </p>
      <a routerLink="/about-me" class="hp__about__link paragraph-one"><span class="hp__about__link-span"></span>Learn more &#8594;</a>
    </div>
  </div>

  <div class="hp__stats">
    <div class="hp__stats__block">
      <h1 class="heading-primary">100+</h1>
      <p class="paragraph-one">Houses Sold</p>
    </div>
    <div class="hp__stats__block">
      <h1 class="heading-primary">$100M</h1>
      <p class="paragraph-one">In Lifetime Sales</p>
    </div>
    <div class="hp__stats__block">
      <h1 class="heading-primary">5 STARS</h1>
      <p class="paragraph-one">On All Reviews</p>
    </div>
  </div>

  <div class="hp__services">
    <h1 class="heading-primary">Services Offered</h1>
    <div class="hp__services__wrapper">
      <div class="hp__services__half">
        <div class="hp__services__section">
          <p class="paragraph-one">Lorem ipsum dolor sit amet</p>
        </div>
        <div class="hp__services__section">
          <p class="paragraph-one">Consectetur adipiscing elit,
            sed do</p>
        </div>
        <div class="hp__services__section">
          <p class="paragraph-one">Hac
            habitasse platea dictumst</p>
        </div>
        <div class="hp__services__section">
          <p class="paragraph-one">Eu consequat ac felis
            donec.</p>
        </div>
      </div>
      <div class="hp__services__half">
        <div class="hp__services__section">
          <p class="paragraph-one">Lorem ipsum dolor sit amet</p>
        </div>
        <div class="hp__services__section">
          <p class="paragraph-one">Consectetur adipiscing elit,
            sed do</p>
        </div>
        <div class="hp__services__section">
          <p class="paragraph-one">Hac
            habitasse platea dictumst</p>
        </div>
        <div class="hp__services__section">
          <p class="paragraph-one">Eu consequat ac felis
            donec.</p>
        </div>
      </div>
    </div>
    <a routerLink="/services-offered" class="paragraph-one hp__services__link"><span class="hp__services__link-span"></span>Learn more &#8594;</a>
  </div>

  

  <app-calendly-widget class="widget"></app-calendly-widget>

  <div class="hp__properties">
    <h1 class="heading-primary">Featured Properties</h1>
    <div class="hp__properties__section">
      <a routerLink="/test-property" class="hp__properties__card">
        <img class="hp__properties__card__img" src="/assets/test-property-one/test-image-1.jpg">
        <h2 class="heading-secondary hp__properties__card__address">123 Peachtree St. Atlanta, GA 30303</h2>
        <p class="paragraph-one hp__properties__card__detail">3 BD | 2 BA | 5000 SQ.FT.</p>
        <p class="paragraph-one hp__properties__card__detail hp__properties__card__detail__price">$1,200,000</p>
      </a>
      <div class="hp__properties__card">
        <img class="hp__properties__card__img" src="/assets/test-property-one/test-image-2.jpg">
        <h2 class="heading-secondary hp__properties__card__address">2265 Marietta Blvd NW Atlanta, GA 30318</h2>
        <p class="paragraph-one hp__properties__card__detail">6 BD | 5 BA | 1400 SQ.FT.</p>
        <p class="paragraph-one hp__properties__card__detail hp__properties__card__detail__price">$750,000</p>
      </div>
    </div>
    
  </div>

  <div class="hp__bs">
    <div class="hp__bs__card">
      <img class="hp__bs__card__img" src="/assets/stock-images/buy-img.jpg">
      <div class="hp__bs__card__overlay"></div>
      <h1 class="heading-primary hp__bs__card__title">BUY</h1>
    </div>
    <div class="hp__bs__card">
      <img class="hp__bs__card__img" src="/assets/stock-images/sell-img.jpg">
      <div class="hp__bs__card__overlay"></div>
      <h1 class="heading-primary hp__bs__card__title">SELL</h1>
    </div>
  </div>
</div>